




















































import { defineComponent, ref } from "@vue/composition-api";
import { isKonsulen } from "@/apps/accounts/modules/store";
import PencapaianKonsulenVM, {
  PencapaianKonsulen,
} from "../models/pencapaianKonsulen";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";
import useViewModel from "@/apps/core/modules/useViewModel";

export default defineComponent({
  name: "PencapaianKonsulen",
  props: {
    konsulen: { type: Object, required: true },
    verifikasi: String,
  },
  components: {
    DatePicker: () => import("@/apps/core/components/DatePicker.vue"),
    DetailField: () => import("@/apps/core/components/DetailField.vue"),
  },
  setup(props) {
    const pencapaianVM = new PencapaianKonsulenVM();
    const { viewModelRef, instance } = useViewModel(
      props.konsulen.id,
      pencapaianVM
    );
    const pencapaian = instance as PencapaianKonsulen;
    const mulai = ref(null);
    const hingga = ref(null);
    const applyFilter = () => {
      pencapaianVM.reset();
      const params = {};
      if (mulai.value !== null) Object.assign(params, {"mulai": mulai.value})
      if (hingga.value !== null) Object.assign(params, {"hingga": hingga.value})
      pencapaianVM.fetch(props.konsulen.id, params);
    };
    return {
      // Data
      hingga,
      mulai,
      pencapaian,
      viewModelRef,

      // Computed
      isKonsulen,
      // foto,

      // Method
      applyFilter,
      toUserDateFormat,
    };
  },
});
