import { API } from "@/apps/core/api";
import PlainModel from "@/apps/core/models/plainModel";
import ViewModel from "@/apps/core/models/viewModel";

export class PencapaianKonsulen extends PlainModel {
    pencapaianList: Array<Record<string, any>> | null = null;
}

export class PencapaianKonsulenAPI extends API {
  static modelPath = "/anggota/pencapaian-konsulen/";

  constructor() {
    super(PencapaianKonsulenAPI.modelPath);
  }
}

export default class PencapaianKonsulenVM extends ViewModel {
  constructor() {
    super(new PencapaianKonsulenAPI(), new PencapaianKonsulen());
  }
}
